<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <DataTable :value="corporationlist" :lazy="true" :paginator="true" :rows="30"
                    :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" class="p-datatable-users"
                    data-key="may1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Corporation</h5>
                        </template>
                        <template v-slot:right>
                            <!-- <Button label="Import" icon="pi pi-paperclip" class="p-mr-2"
                                @click="onImportCorporation" /> -->
                            <Button label="Add Corporation" icon="pi pi-plus" class=" p-mr-2"
                                @click="addCorporationDialogOpen" />
                                <Button v-if="!showFillFilter" label="Filter" icon="pi pi-filter" @click="filterlisting()"></Button>
                            <Button v-if="showFillFilter" label="Filter" icon="pi pi-filter-slash" class="p-button-success" @click="filterlisting()"></Button>
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column header="Sr." headerStyle="width: 4%">
                        <template #body="{ index }">
                            <div>{{ genrateSrNo(index) }}</div>
                        </template>
                    </Column>
                    <Column field="name" header="Corporation Detail" headerStyle="width: 38%">
                        <template #body="{data}">
                            <div>
                                <div class="p-text-capitalize"><span v-tooltip.right="'Vidhan Sabha'">{{ data.may3 ?
                                data.may3 : 'N/A' }}</span></div>
                                <div class="p-text-capitalize p-mt-1"><small class="muted-text"
                                        v-tooltip.right="'Prabhag'">{{ data.may12 ? data.may12 : 'N/A' }}</small></div>
                                <div class="p-text-capitalize p-mt-1"><small class="muted-text"
                                        v-tooltip.right="'Ward Name & Number'">{{ data.may13 ? data.may13 : 'N/A' }}
                                        <span v-if="data.may14">({{data.may14}})</span></small></div>
                            </div>
                        </template>
                    </Column>
                    <Column header="State" headerStyle="width: 15%">
                        <template #body="{data}">
                            <div class="p-text-capitalize">{{ data.may5 ? data.may5 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="District" headerStyle="width: 15%">
                        <template #body="{data}">
                            <div class="p-text-capitalize">{{ data.may9 ? data.may9 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="Corporation" headerStyle="width: 19%">
                        <template #body="{data}">
                            <span v-if="data.may10 == 1"
                                :class="'user-badge status-temp-suspend'">Mahanagarpalika</span>
                            <span v-if="data.may10 == 2" :class="'user-badge status-temp-suspend'">Nagarpalika</span>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 9%" class="p-text-center">
                        <template #body="{data}">
                            <Button icon="pi pi-pencil" class="p-button-rounded "
                                @click="editCorporationDialogOpen(data)" />
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
    <!-- add corporation dialog start here -->
    <Dialog v-model:visible="addCorporationStatus" :style="{ width: '800px' }" header="Add Corporation" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="state">
                        State
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="state" v-model="state" :options="stateList" @change="getdistrictName($event)" optionLabel="label"
                        placeholder="Select ..." autofocus :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.state.$error">{{
                    v$.state.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="district">
                        District
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="district" v-model="district" @change="getvishansabhaName($event)" :options="districtList" optionLabel="label"
                        placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.district.$error">{{
                    v$.district.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="vidhansabha">
                        Vidhan Sabha
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="vidhansabha" v-model="vidhansabha" :options="vidhansabhaList" optionLabel="label"
                        placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.vidhansabha.$error">{{
                    v$.vidhansabha.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label>Corporation Type <span class="p-invalid p-error"> *</span></label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="mahanagarpalika" name="option" v-bind:value="1" v-model="corporationtype"
                            :checked="corporationtype == 1" />
                        <label for="Mahanagarpalika_type" class="p-mr-2">Mahanagarpalika</label>
                        <RadioButton id="nagarpalika" name="option" v-bind:value="2" v-model="corporationtype"
                            :checked="corporationtype == 2" />
                        <label for="nagarpalika" class="p-mr-2">Nagarpalika</label>
                    </div>
                    <small class="p-invalid p-error" v-if="v$.corporationtype.$error">{{
                    v$.corporationtype.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="prabhag">
                        Prabhag
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="prabhag" class="p-text-capitalize" v-model.trim="prabhag" required="true"
                        maxlength="20" autofocus :class="{ 'p-invalid': submitted && !prabhag }" />
                    <small class="p-invalid p-error" v-if="v$.prabhag.$error">{{
                    v$.prabhag.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="wardname">
                        Ward Name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="wardname" class="p-text-capitalize" v-model.trim="wardname" required="true"
                        maxlength="20" autofocus :class="{ 'p-invalid': submitted && !wardname }" />
                    <small class="p-invalid p-error" v-if="v$.wardname.$error">{{
                    v$.wardname.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="wardnumber">
                        Ward Number
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="wardnumber" class="p-text-capitalize" v-model.trim="wardnumber" required="true"
                        @keypress="onlyNumber" maxlength="20" autofocus
                        :class="{ 'p-invalid': submitted && !wardnumber }" />
                    <small class="p-invalid p-error" v-if="v$.wardnumber.$error">{{
                    v$.wardnumber.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="reservationvalue">
                        Reservation Seats
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="reservationvalue" v-model="reservationvalue" :options="reservationList"
                        optionLabel="label" placeholder="Select ..." :filter="false" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.reservationvalue.$error">{{
                    v$.reservationvalue.$errors[0].$message
                    }}</small>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="addCorporation" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- add corporation dialog end here -->
    <!-- edit corporation dialog start here -->
    <Dialog v-model:visible="editCorporationStatus" :style="{ width: '800px' }" header="Edit Corporation" :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="state">
                        State
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="state" v-model="state" :options="stateList" @change="getdistrictName($event)" optionLabel="label"
                        placeholder="Select ..." autofocus :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.state.$error">{{
                    v$.state.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="district">
                        District
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="district" v-model="district" @change="getvishansabhaName($event)" :options="districtList" optionLabel="label"
                        placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.district.$error">{{
                    v$.district.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="vidhansabha">
                        Vidhan Sabha
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="vidhansabha" v-model="vidhansabha" :options="vidhansabhaList" optionLabel="label"
                        placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.vidhansabha.$error">{{
                    v$.vidhansabha.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label>Corporation Type <span class="p-invalid p-error"> *</span></label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="mahanagarpalika" name="option" v-bind:value="1" v-model="corporationtype"
                            :checked="corporationtype == 1" />
                        <label for="Mahanagarpalika_type" class="p-mr-2">Mahanagarpalika</label>
                        <RadioButton id="nagarpalika" name="option" v-bind:value="2" v-model="corporationtype"
                            :checked="corporationtype == 2" />
                        <label for="nagarpalika" class="p-mr-2">Nagarpalika</label>
                    </div>
                    <small class="p-invalid p-error" v-if="v$.corporationtype.$error">{{
                    v$.corporationtype.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="prabhag">
                        Prabhag
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="prabhag" class="p-text-capitalize" v-model.trim="prabhag" required="true"
                        maxlength="20" autofocus :class="{ 'p-invalid': submitted && !prabhag }" />
                    <small class="p-invalid p-error" v-if="v$.prabhag.$error">{{
                    v$.prabhag.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="wardname">
                        Ward Name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="wardname" class="p-text-capitalize" v-model.trim="wardname" required="true"
                        maxlength="20" autofocus :class="{ 'p-invalid': submitted && !wardname }" />
                    <small class="p-invalid p-error" v-if="v$.wardname.$error">{{
                    v$.wardname.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="wardnumber">
                        Ward Number
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="wardnumber" class="p-text-capitalize" v-model.trim="wardnumber" required="true"
                        @keypress="onlyNumber" maxlength="20" autofocus
                        :class="{ 'p-invalid': submitted && !wardnumber }" />
                    <small class="p-invalid p-error" v-if="v$.wardnumber.$error">{{
                    v$.wardnumber.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="reservationvalue">
                        Reservation Seats
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="reservationvalue" v-model="reservationvalue" :options="reservationList"
                        optionLabel="label" placeholder="Select ..." :filter="false" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.reservationvalue.$error">{{ v$.reservationvalue.$errors[0].$message
                    }}</small>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="updateCorporation" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- edit corporation dialog end here -->
     <!----Start of filter--------------------------------->
     <Dialog v-model:visible="filterListingDialog" :style="{ width: '800px' }" header="Filter" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="state">State</label>
                    <MultiSelect v-model="state_fks" :options="stateList" optionValue="value" optionLabel="label"
                        placeholder="Select" :filter="true" class="multiselect-custom" appendTo="body"></MultiSelect>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="loksabhaname">District</label>
                    <MultiSelect v-model="district_fks" :options="districtList" optionValue="value" optionLabel="label"
                        placeholder="Select" :filter="true" class="multiselect-custom" appendTo="body"></MultiSelect>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="loksabhaname">Vidhan Sabha</label>
                    <MultiSelect v-model="vidhansabha_fks" :options="vidhansabhaSelect" optionValue="value"
                        optionLabel="label" placeholder="Select" :filter="true" class="multiselect-custom"
                        appendTo="body"></MultiSelect>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label>Corporation Type</label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="mahanagarpalika" name="option" v-bind:value="1" v-model="corporationtype_fks" />
                        <label for="Mahanagarpalika_type" class="p-mr-2">Mahanagarpalika</label>
                        <RadioButton id="nagarpalika" name="option" v-bind:value="2" v-model="corporationtype_fks" />
                        <label for="nagarpalika" class="p-mr-2">Nagarpalika</label>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Reset" icon="pi pi-refresh" class="p-button-text" @click="reSetFilteredData()" />
            <Button label="Search" icon="pi pi-search" class="p-mr-2" autofocus
                @click="getFilteredData(state_fks, district_fks, vidhansabha_fks, corporationtype_fks)" />
        </template>
    </Dialog>
    <!----End of filter--------------------------------->

    <!-- import csv dialog start here -->
    <Dialog v-model:visible="isImportCorporation" :style="{ width: '1100px' }" header="Import Corporation" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6" align="left">
                    <FileUpload mode="basic" name="demo[]" url="./upload" accept="text/csv" :maxFileSize="1000000" @upload="onUpload" />
                </div>
                <div class="p-field p-col-12 p-md-6" align="right">
                        <label class="p-mr-2">
                            Download Sample Sheet:
                        </label>
                        <!-- TODO: Need CSV File URL here -->
                        <router-link href="replace-csv-string" target="_blank" to="#">
                            <Button icon="pi pi-download" v-tooltip.left="'Download Sample Sheet'" class="p-button-rounded">
                            </Button>
                        </router-link>
                </div>
                <div class="p-field p-col-12">
                    <h5 class="indigo-color p-m-0 p-pl-1">Note:</h5>
                    <ol style="line-height: 1.5;">
                        <li>The file name should be same as sample file name "import_open_enquiries.csv".</li>
                        <li>Do not just change any files extension from .XLS or any other extension to CSV and upload it.</li>
                        <li>You can upload enquiries in one sheet up to 5000 enquiries in one attempt.</li>
                        <li>Don't change and delete any column name in CSV sheet.</li>
                        <li>Date format must be in "dd-mm-yyyy".</li>
                        <li>Insert employee mobile number properly to allocate the enquiry to a particular employee, else enquiry will be inserted into logged-in employees BMS account.</li>
                        <li>Project name, unit type & source must be the same as in the BMS System.</li>
                        <li>In CSV sheet enquiry date, first name or last name, mobile or email, employee mobile, source column's are mandatory.</li>
                        <li>Submitted sheets for importing enquiries till 10 pm will be executed on the same night and the result will be available by 7 am next day</li>
                    </ol>
                </div>
            </div>
        </div>
        <template #footer>
                <Button label="Submit" icon="pi pi-check" class="p-button-text"
                @click="onSubmit" />
        </template>
    </Dialog>
    <!-- import dialog end here -->
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { required, helpers, numeric } from '@vuelidate/validators';
export default {
    data() {
        return {
            v$: useValidate(),
            corporationlist: [],
            page_no: 0,
            totalRecords: 0,
            loading: false,
            addCorporationStatus: false,
            editCorporationStatus: false,
            state: '',
            stateList: [],
            district: '',
            districtList: [],
            vidhansabha: '',
            vidhansabhaList: [],
            corporationtype: '',
            prabhag: '',
            wardname: '',
            wardnumber: '',
            reservationvalue: '',
            reservationList: [],
            showLoader: false,
            submitted: false,
            row_id: null,
            filterListingDialog: false,
            state_fks: [],
            district_fks: [],
            vidhansabha_fks: [],
            vidhansabhaSelect: [],
            corporationtype_fks: [],
            showFillFilter: false,
            isImportCorporation: false,
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.loading = true;
        this.getcorporationlist();
        this.getReservations();
        // this.getvidhansabha();
        this.getStates();
    },
    validations() {
        return {
            state: { required: helpers.withMessage('Please select state', required) },
            district: { required: helpers.withMessage('Please select district', required) },
            vidhansabha: { required: helpers.withMessage('Please select vidhan sabha', required) },
            corporationtype: { required: helpers.withMessage('Please select corporation type', required) },
            prabhag: { required: helpers.withMessage('Please enter prabhag', required) },
            wardname: { required: helpers.withMessage('Please enter ward name', required) },
            wardnumber: { required: helpers.withMessage('Please enter ward number', required), numeric: helpers.withMessage('Only numbers are allowed', numeric) },
            reservationvalue: { required: helpers.withMessage('Please select reservation', required) },
        };
    },
    methods: {
        onImportCorporation(){
            this.isImportCorporation = true;
        },
        getdistrictName(e) {
            this.vidhansabha = '';
            this.corporationtype = '';
            this.prabhag = '';
            this.wardname = '';
            this.wardnumber = '';
            if (e.value) {
                this.ApiService.getdistrict({ state_id: e.value.value }).then((items) => {
                    if (items.success === true) {
                        this.districtList = items.data;
                    }
                });
            }
        },
        getvishansabhaName(e) {
            this.corporationtype = '';
            this.prabhag = '';
            this.wardname = '';
            this.wardnumber = '';
            if (e.value) {
                this.ApiService.getvidhansabha({ district_id: e.value.value }).then((items) => {
                    if (items.success === true) {
                        this.vidhansabhaList = items.data;
                    }
                });
            }
        },
        getStates(ev) {
            this.ApiService.getStates(ev).then((data) => {
                if (data.status == 200) {
                    this.stateList = data.data;
                } else {
                    this.stateList = '';
                }
            });
        },
        getReservations(ev) {
            this.ApiService.getReservations(ev).then((data) => {
                if (data.status == 200) {
                    this.reservationList = data.data;
                } else {
                    this.reservationList = '';
                }
            });
        },
        // getvidhansabha(ev) {
        //     this.ApiService.getvidhansabha(ev).then((data) => {
        //         if (data.status == 200) {
        //             this.vidhansabhaList = data.data;
        //         } else {
        //             this.vidhansabhaList = '';
        //         }
        //     });
        // },
        getcorporationlist(ev) {
            this.loading = true;
            this.ApiService.getcorporationlist(ev).then((data) => {
                if (data.status == 200) {
                    this.corporationlist = data.data.result;
                    this.totalRecords = data.data.count;
                } else {
                    this.corporationlist = '';
                    this.totalRecords = 0;
                }
                this.loading = false;
            });
        },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getcorporationlist({ 
                page_no: event.page,
                statefilter: this.event_state,
                districtfilter: this.event_district,
                vidhansabhafilter: this.event_vidhansabha,
                corporationtypefilter: this.event_corporationtype
             });
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        addCorporationDialogOpen() {
            this.addCorporationStatus = true;
            this.submitted = false;
            this.state = '';
            this.district = '';
            this.vidhansabha = '';
            this.corporationtype = '';
            this.prabhag = '';
            this.wardname = '';
            this.wardnumber = '';
            this.reservationvalue = '';
        },
        editCorporationDialogOpen(e) {
            this.getcorporationlist();
            this.editCorporationStatus = true;
            this.submitted = false;
            this.row_id = e.may1;
            let may4 = this.stateList.filter(function (item) {
                return item.value == e.may4;
            });
            if (may4.length > 0) {
                this.state = may4[0];
            }
            let may8 = this.districtList.filter(function (item) {
                return item.value == e.may8;
            });
            if (may8.length > 0) {
                this.district = may8[0];
            }
            let may2 = this.vidhansabhaList.filter(function (item) {
                return item.value == e.may2;
            });
            if (may2.length > 0) {
                this.vidhansabha = may2[0];
            }
            this.corporationtype = e.may10;
            this.prabhag = e.may12;
            this.wardname = e.may13;
            this.wardnumber = e.may14;
            let may15 = this.reservationList.filter(function (item) {
                return item.value == e.may15;
            });
            if (may15.length > 0) {
                this.reservationvalue = may15[0];
            }
        },
        addCorporation() {
            this.submitted = true;
            this.errormsg = '';
            let fields = {};
            fields['may4'] = this.state.value;
            fields['may5'] = this.state.label;
            fields['may8'] = this.district.value;
            fields['may9'] = this.district.label;
            fields['may2'] = this.vidhansabha.value;
            fields['may3'] = this.vidhansabha.label;
            fields['may10'] = this.corporationtype;
            fields['may12'] = this.prabhag;
            fields['may13'] = this.wardname;
            fields['may14'] = this.wardnumber;
            fields['may15'] = this.reservationvalue.value;
            fields['may16'] = this.reservationvalue.label;

            this.v$.$validate();
            if (!this.v$.$error) {
                this.loading = true;
                this.showLoader = true;
                this.ApiService.createcorporation(fields).then((items) => {
                    if (items.status == 200) {
                        this.loading = false;
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.addCorporationStatus = false;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.state = '';
                        this.district = '';
                        this.vidhansabha = '';
                        this.corporationtype = '';
                        this.prabhag = '';
                        this.wardname = '';
                        this.wardnumber = '';
                        this.reservationvalue = '';
                        this.getcorporationlist();
                    } else {
                        this.loading = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        updateCorporation() {
            this.submitted = true;
            this.errormsg = '';
            let fields = {};
            fields['may1'] = this.row_id;
            fields['may4'] = this.state.value;
            fields['may5'] = this.state.label;
            fields['may8'] = this.district.value;
            fields['may9'] = this.district.label;
            fields['may2'] = this.vidhansabha.value;
            fields['may3'] = this.vidhansabha.label;
            fields['may10'] = this.corporationtype;
            fields['may12'] = this.prabhag;
            fields['may13'] = this.wardname;
            fields['may14'] = this.wardnumber;
            fields['may15'] = this.reservationvalue.value;
            fields['may16'] = this.reservationvalue.label;
            this.v$.$validate();
            if (!this.v$.$error) {
                this.loading = true;
                this.showLoader = true;
                this.ApiService.createcorporation(fields).then((items) => {
                    if (items.status == 200) {
                        this.loading = false;
                        var successMsg = items.message;
                        this.editCorporationStatus = false;
                        this.showLoader = false;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.getcorporationlist();
                    } else {
                        this.loading = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        filterlisting() {
            this.filterListingDialog = true;
            this.getVidhansabhaFilter();
            this.getStatesFilter();
            this.getDistrictFilter();
            this.getLokhsabhaFilter();
        },
        getFilteredData(event_state, event_district, event_vidhansabha, event_corporationtype) {
            this.loading = true;
            this.state_fks = event_state;
            this.district_fks = event_district;
            this.vidhansabha_fks = event_vidhansabha;
            this.corporationtype_fks = event_corporationtype;
            this.getcorporationlist({
                statefilter: this.state_fks,
                districtfilter: this.district_fks,
                vidhansabhafilter: this.vidhansabha_fks,
                corporationtypefilter: this.corporationtype_fks
            });
            this.showFillFilter = true;
            this.filterListingDialog = false;
        },
        reSetFilteredData() {
            this.state_fks = "";
            this.district_fks = '';
            this.vidhansabha_fks = '';
            this.corporationtype_fks = '';
            this.getcorporationlist();
            this.showFillFilter = false;
            this.filterListingDialog = false;
        },
        getVidhansabhaFilter(ev) {
            this.ApiService.getvidhansabha(ev).then((data) => {
                if (data.status == 200) {
                    this.vidhansabhaSelect = data.data;
                } else {
                    this.vidhansabhaSelect = '';
                }
            });
        },
        getStatesFilter(ev) {
            this.ApiService.getStates(ev).then((data) => {
                if (data.status == 200) {
                    this.stateList = data.data;
                } else {
                    this.stateList = '';
                }
            });
        },
        getDistrictFilter(ev) {
            this.ApiService.getdistrict(ev).then((items) => {
                if (items.success === true) {
                    this.districtList = items.data;
                } else {
                    this.districtList = '';
                }
            });
        },
    },
};
</script>
<style scoped lang="scss">
.user-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-active {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-per-suspend {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-temp-suspend {
        background: #ffd8b2;
        color: #805b36;
    }
}
</style>
